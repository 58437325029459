:root {
    --dark: #000;
    --gray: #181818;
    --dark-transparency: rgba(10, 18, 31, 0.75);
    --primary: #1f7ecc;
}

/* Forms */

/* Tables */

/* Menu */

/* Card */

.dropdown-item:hover {
    background-color: var(--primary);
}

/* Text */

.text-primary {
    color: var(--primary) !important;
}

/* Overlays */

.overlay {
    background-color: #000;
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0.75;
    z-index: 1;
}


.black-overlay {
    background-color: #000;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    opacity: 0.4;
    z-index: 0;
}

/* Background */

.bg-gray {
    background-color: var(--gray);
}

.bg-primary {
    background-color: var(--primary) !important;
    border-color: var(--primary);
}

.bg-dark {
    background-color: var(--dark) !important;
}

.bg-opacity {
    background-color: var(--dark-transparency) !important;
}

.bg-black {
    background-color: #000;
}

/* Buttons */

.btn-primary, .btn-primary:focus,
.btn-primary:active {
    background-color: var(--primary);
    border-color: var(--primary);
    color: white;
}

.btn-primary:hover {
    background-color: #000;
    color: white;
}

.btn-outline-primary,
.btn-outline-primary:focus,
.btn-outline-primary:active {
    background-color: transparent;
    border-color: var(--primary);
    color: var(--primary);
}

.btn-outline-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--dark);
}