.scroll-wrapper {
    margin: auto;
    max-width: 100%;
    overflow: hidden;
}

.scroll-wrapper-inner {
    width: 2000px;
}

@media(min-width: 767px) {
    .scroll-item {
        /* animation properties */
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -moz-animation: item-scroll 15s linear infinite;
        -webkit-animation: item-scroll 15s linear infinite;
        animation: item-scroll 15s linear infinite;
    }

    /* for Firefox */
    @-moz-keyframes item-scroll {
        from {
            -moz-transform: translateX(100%);
        }

        to {
            -moz-transform: translateX(-100%);
        }
    }

    /* for Chrome */
    @-webkit-keyframes item-scroll {
        from {
            -webkit-transform: translateX(100%);
        }

        to {
            -webkit-transform: translateX(-100%);
        }
    }

    @keyframes item-scroll {
        from {
            -moz-transform: translateX(100%);
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }

        to {
            -moz-transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }
    }
}

@media (max-width: 767px) {
    .scroll-item {
        /* animation properties */
        -moz-transform: translateX(200%);
        -webkit-transform: translateX(200%);
        transform: translateX(200%);
        -moz-animation: item-scroll 15s linear infinite;
        -webkit-animation: item-scroll 15s linear infinite;
        animation: item-scroll 15s linear infinite;
    }

    .scroll-wrapper-inner {
        margin-left: -900px;
    }

    /* for Firefox */
    @-moz-keyframes item-scroll {
        from {
            -moz-transform: translateX(200%);
        }

        to {
            -moz-transform: translateX(-200%);
        }
    }

    /* for Chrome */
    @-webkit-keyframes item-scroll {
        from {
            -webkit-transform: translateX(200%);
        }

        to {
            -webkit-transform: translateX(-200%);
        }
    }

    @keyframes item-scroll {
        from {
            -moz-transform: translateX(200%);
            -webkit-transform: translateX(200%);
            transform: translateX(200%);
        }

        to {
            -moz-transform: translateX(-200%);
            -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
        }
    }
}