h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
}

span,
p,
div,
a,
input,
body {
    font-family: "Montserrat", sans-serif !important;
}

#landing {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.montserrat {
    font-family: "Montserrat", sans-serif;
}

.text-montserrat {
    font-family: "Montserrat", sans-serif !important;
}

.nav-link {
    font-size: 14px;
    font-family: "Montserrat", sans-serif !important;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 700;
    margin-top: 8px;
    color: #fff !important;
}

.vigencia {
    font-size: 18px;
}